<template>
  <div>
    <div class="row">
      <!-- <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-primary`" size="45">
              <feather-icon size="21" :icon="'UsersIcon'" />
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.users }}
              </h2>
              <span>Usuários Cadastrados</span>
            </div>
          </b-card-body>
        </b-card>
      </div>

      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-info`" size="45">
              <i class="fas fa-tag" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.pedidos }}
              </h2>
              <span>Total de Pedidos</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-warning`" size="45">
              <i class="fas fa-tag" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.pedidos_separacao }}
              </h2>
              <span>Pedidos em Separação (Integrado SERBOM)</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-warning`" size="45">
              <i class="fas fa-tag" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.pedidos_separados }}
              </h2>
              <span>Pedidos Separados (Aguardando Bling)</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-success`" size="45">
              <i class="fas fa-tag" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.pedidos_finalizados }}
              </h2>
              <span>Pedidos Finalizados</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-danger`" size="45">
              <i class="fas fa-tag" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.pedidos_cancelados }}
              </h2>
              <span>Pedidos Cancelados Manualmente</span>
            </div>
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-3">
        <b-card no-body>
          <b-card-body class="pb-0">
            <b-avatar class="mb-1" :variant="`light-danger`" size="45">
              <i class="fas fa-tag" style="font-size: 18px"></i>
            </b-avatar>
            <div class="truncate mb-2">
              <h2 class="mb-25 font-weight-bolder">
                {{ dashboard.highlights.pedidos_erro }}
              </h2>
              <span>Pedidos com Erro</span>
            </div>
          </b-card-body>
        </b-card>
      </div> -->
    </div>
  </div>
</template>

<script>
import { BCardText, BLink } from "bootstrap-vue";

import { BCard, BCardBody, BAvatar } from "bootstrap-vue";

export default {
  data() {
    return {
      dashboard: {
        highlights: {},
      },
    };
  },
  components: {
    BCard,
    BCardText,
    BLink,
    BCard,
    BCardBody,
    BAvatar,
  },
  methods: {
    getDashboard() {
      const self = this;
      const api = self.$store.state.api + "dashboard";

      axios
        .post(api)
        .then((response) => {
          self.dashboard = response.data;
        })
        .catch((error) => {
          self.$message(null, error.response.data, "error");
        });
    },
  },
  mounted() {
    const self = this;
    self.getDashboard();
  },
  created() { },
};
</script>

<style></style>
